define(function (require, exports, module) {

    require('app/promise_always');
    
    var $ = require('jquery');
    var can = require('app/my_can');
    var Messages = require('app/messages_handler');
    var db_shim = require('lib/IndexedDBShim');
    var db = require('lib/db');
    var I18N = require('app/i18n').default;
    var util = require('app/jquery.util');
    
    var maxkir = require('app/maxkir');
    maxkir = window.maxkir;
    window.$ = $;

    console.info("App is loaded");

    /**
     * @class App
     */
    var app = window.CV = {
        config: {
            url_prefix: "",
            api_url: "https://beta.checkvist.com"
        },

        util: util,

        /**
         * @type {Messages}
         */
        msg: Messages,
        can: can,

        debug: maxkir.debug,
        info: maxkir.info,
        warn: maxkir.warn,

        is_testing: function() {
            return typeof QUnit === 'object';
        },

        error: function() {
            var args = [new Error("APP ERROR:")];
            for (var i = 0; i < arguments.length; i++) {
                args.push(arguments[i]);
            }
            console.error.apply(console, args);
        },
        
        create_ajax_error: function(jXHR, message, error_code) {
            var err = new Error(message + " - " + jXHR.status + ": " + error_code);
            err.error_code = error_code;
            err.xhr = jXHR;
            err.auth_problem = jXHR.status === 401 || jXHR.status === 403;
            err.need_2fa_token = jXHR.status === 422;
            err.is_abort = jXHR.statusText === 'abort';
            return err;
        },

        check_for_internet_connection: function() {
            return new Promise(function(resolve, reject) {
                $.ajax({
                    url: app.config.api_url + "/checklists/none/tick"
                })
                    .then(function (data) {
                        app.debug("ONLINE");
                        resolve();
                    }, function(err) {
                        app.debug("OFFLINE");
                        reject(app.create_ajax_error(err, "Offline", "ERR_OFFLINE"));
                    });
            });
        },

        view_log: function() {
            var log = $('#fullLog');
            if (!log.length) {
                var logElement = document.createElement("div");
                logElement.id = 'fullLog';
                logElement.style.position = 'absolute';
                logElement.style.lineHeight = '1.5';
                logElement.style.top = '40px';
                logElement.style.width = '100%';
                logElement.style.height = '90%';
                logElement.style.fontSize = '80%';
                logElement.style.overflow = 'auto';
                logElement.style.backgroundColor = '#f0f0f0';
                logElement.style.color = '#222';

                $('main').append(logElement);
            }
            else {
                logElement = log[0];
            }

            var dump_log = function(element) {
                element.innerHTML += "<pre>" + maxkir.local_log_text() + "</pre>";
                element.scrollTop = element.scrollHeight;
            };

            dump_log(logElement);
        },

        view_template: function(template, model, helpers, callback) {
            can.view(this.config.url_prefix + '/ejs/' + template, model, helpers, callback);
        },

        /**
         * @param {Array} target
         * @param {Array} new_data
         */
        replace_model: function(target, new_data) {
            // target.replace(new_data);
            // return;
            var new_map = {};
            new_data.forEach(function(d) { 
                new_map[d.id] = d; 
            });

            can.batch.start();
            var to_remove = [];
            target.forEach(function(r, idx) {
                if (new_map[r.id]) {
                    if (r !== new_map[r.id]) {
                        // console.warn("Update", r, new_map[r.id]);
                        r.attr(new_map[r.id], true);
                    }
                    delete new_map[r.id];
                }
                else {
                    // console.warn("Remove", r);
                    to_remove.push(idx);
                }
            });
            for(var i = to_remove.length - 1; i >= 0; i --) {
                target.splice(to_remove[i], 1);
            }
            Object.values(new_map).forEach(function(v) {
                // console.warn("Create", v);
                target.push(v);
            });
            // console.warn(app.util.dump(Object.values(mxTask.store)))
            can.batch.stop();
        },

        /**
         * @param {Array} deferreds list of deferreds to run
         * @return {Promise}
         */
        run_all: function(deferreds) {
            var that = this;
            
            return new Promise(function(resolve, reject) {
                var working_copy = deferreds.slice(0);
                deferreds.splice(0); // For a case if deferreds will re-appear

                var on_done = function() {
                    that.run_all(deferreds).then(resolve, reject);
                };

                if (working_copy.length > 0) {
                    // Run all remove operations and when all data is removed - run new store
                    Promise.all(working_copy).then(on_done, reject);
                }
                else {
                    resolve();
                }
            });
        },

        storage: {
            get_item: function(key) {
                try {
                    return JSON.parse(localStorage.getItem(key));
                } catch (e) {
                    app.error(e);
                    return null;
                }
            },
            set_item: function(key, value) {
                if (value === null) {
                    localStorage.removeItem(key);
                }
                else {
                    localStorage.setItem(key, JSON.stringify(value));
                }
            },

            dump_table: function(table) {
                return app.storage.open_db()
                    .then(function db_opened(server){

                        server.query(table, 'parent_id').only(0).execute().then(function records_from_db(records) {
                            app.info("DUMP OF " + table);
                            records.forEach(function( rec ) {
                                var l = "  ";
                                Object.keys(rec).sort().forEach(function(k) {
                                    l += k + "='" + rec[k] + "'[" + (typeof rec[k]).charAt(0) + "] "
                                });
                                app.info(l);
                            })
                        });

                    });
            },

            clear_items_start_with: function(prefix) {
//                app.debug("clear_items_start_with " + prefix);
                var to_remove = [];
                for (var i = 0; i < localStorage.length; i++) {
                    var key = localStorage.key(i);
                    if (key.indexOf(prefix) === 0) {
                        to_remove.push(key);
                    }
                    else {
//                        app.debug("KEEP  mark for key " + key);
                    }
                }

                for(i = 0; i < to_remove.length; i ++) {
//                    app.debug("REMOVE  mark for key " + to_remove[i]);
                    localStorage.removeItem(to_remove[i]);
                }
            },

            open_db: function() {
                var self = this;
                
                if (self.connection) {
                    return Promise.resolve(self.connection);
                }
                else {
                    if (!self.connection_promise) {
                        self.connection_promise = new Promise(function(resolve, reject) {
                            db.open({
                                server: 'checkvist-app' + (app.is_testing() ? "-tests" : ""),
                                version: 71,
                                schema: {
                                    files_storage: { },
                                    lists: { key: { keyPath: 'id' } },
                                    list_states: { key: { keyPath: 'id' } },
                                    users: { key: { keyPath: 'id' } },
                                    temp_objects: { key: { keyPath: 'id' } },
                                    tasks: {
                                        key: { keyPath: 'id' },
                                        indexes: {
                                            checklist_id: { },
                                            parent_id: { }
                                        }
                                    }
                                },
                                onupgradeneeded: function(e) {
                                    app.info("Clearing data after database upgrade", e.oldVersion, e.newVersion);
                                    var db = e.target.result;
                                    
                                    if (e.oldVersion === 57) {
                                        app.storage.clear_items_start_with('tasks');
                                        app.storage.clear_items_start_with('lists');
                                        app.storage.clear_items_start_with('users');
                                    }
                                    if (e.oldVersion <= 62) {
                                        try {
                                            db.deleteObjectStore('commands');
                                        }
                                        catch(e) {
                                            app.info("Could not remove table commands: " + e);
                                        }
                                    }
                                }
                            }).then(function (connection) {
                                self.connection = connection;
                                app.debug("Got DB connection", connection);
                                resolve(connection);
                            }, function (e) {
                                app.debug("Unable to get DB connection", JSON.stringify(e), e.stack);
                                reject(e);
                            })

                        });
                    }
                    
                    return self.connection_promise;
                }
            },

            /**
             * Write records to IndexedDB table
             * @param {String} table_name
             * @param {Array} records
             * @return Promise which resolves to written objects 
             */
            update_records_in_db: function(table_name, records) {
                return this.open_db().then(function update_records_in_db(server) {
                    return server[table_name].update.apply(this, records)
                });
            },

            close: function() {
                if (this.connection) {
                    this.connection_promise = null;
                    this.connection.close();
                    this.connection = null;
                    app.debug("[DB] Closed");
                }
            }

        }
    };


    can.Mustache.registerHelper('t',
        function (code) {
            return I18N.t(code);
        });

    // Enable lazy loading for child list items
    app.lazy_expand = true;
    
    module.exports = app;
});