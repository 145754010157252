import app from "app";
import Page from "app/pages/page";
import User from "app/model/user";

const SignInPage = Page.extend({
        defaults: {
            template: "signIn_page.mustache",
            page_title: "Checkvist: " + I18N.t("signIn").toLocaleLowerCase()
        }
    },
    {
        init: function(element, options) {
            Page.prototype.init.apply(this, arguments);
            $('#user_email').focus();

            this._check_for_https();
        },

        'form.signinForm submit': function (form, event) {
            event.preventDefault();

            var on_error = function (error) {
                maxkir.warn(error);
                if (error.auth_problem) {
                    app.msg.show_error(I18N.t("err.cannot_login"));
                }
                else {
                    app.msg.show_error(I18N.t("err.no_internet"));
                }
            };

            form = form[0];
            const user = new User();

            user.signInPasswordless(form.elements['username'].value, form.elements['commit']).then(() => {
                if (cv_route.from_url()) {
                    cv_route.goTo(cv_route.from_url());
                }
                else {
                    cv_route.goTo("/");
                }
            }, on_error)

            // Hiding the iOS keyboard:
            document.activeElement?.blur();
        },

        'form.signinForm input focus': function () {
            app.msg.hide_error();
        }

    });

export default SignInPage;