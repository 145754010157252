import app from "app";

import User from "app/model/user";
import Task from "app/model/task";

import IntroPage from "app/pages/intro_page";

import SignInPage from "app/pages/signIn_page";

import ListsPage from "app/pages/lists_page";
// var ListPage = require('app/pages/list_page');
import SettingsPage from "app/pages/settings_page";

import HelpPage from "app/pages/help_page";

import AboutPage from "app/pages/about_page";

import BookmarkletPage from "app/pages/bookmarklet_page";
// var DuePage = require('app/pages/due_page');
import AddListPage from "app/pages/add_list_page";
import AddNotePage from "app/pages/add_note_page";

import 'lib/path';

// Path library is from https://github.com/kir/pathjs
let current_page;

const main = function () {
    return $("main").first();
};

const kill_current_page = function () {
    if (current_page) {
        current_page.destroy();
        current_page = null;
    }
};

const DEFAULT_LAST_PAGE = "/app/lists.html";

const remember_last_page = function (page) {
    // console.warn("remember last page " + page);
    app.storage.set_item('last_page', page);
};
const get_last_page = function () {
    return app.storage.get_item('last_page') || DEFAULT_LAST_PAGE;
};

const retry_after_relogin = function(route) {
    User.token_login(this.params['token']).then(function () {
        var routeWithoutToken = route.replace(/[?&]token=\w+/, "");
        cv_route.goTo(routeWithoutToken);
    }, function () {
        cv_route.goTo("/app/signIn.html", route);
    })
};


const go_to_last_page_and_forget = function() {
    const lastPage = get_last_page();
    remember_last_page(null);
    cv_route.goTo(lastPage);
    cv_route.forget_from();
};

// Configure routing:
Path.map("/").to(function () {

    User.silent_login(function () {
        go_to_last_page_and_forget()
    }, function () {
        cv_route.goTo("/app/signIn.html");
    });
});

Path.map("/app/signIn.html").to(function () {
    User.silent_login(function () {
        go_to_last_page_and_forget()
    }, function () {
        kill_current_page();
        current_page = new SignInPage(main());
    });
});

const initOnlinePage = function (name, pageClass, options) {
    Path.map("/app/" + name + ".html(?token=:token)", options && options.url_params || []).to(function () {
        const route = this.last_matched_path;
        const that = this;

        User.silent_login(function () {

            if (route.indexOf(DEFAULT_LAST_PAGE) === -1) {
                remember_last_page(route);
            }
            else {
                if (get_last_page() !== DEFAULT_LAST_PAGE) {
                    go_to_last_page_and_forget();
                    return;
                }
            }

            var pageParams = {params: that.params_decoded() };
            if (current_page instanceof pageClass) {
                current_page.refresh_options(pageParams);
            }
            else {
                kill_current_page();
                current_page = new pageClass(main(), pageParams);
            }
        }, retry_after_relogin.bind(this, route));
    });
};

initOnlinePage("intro", IntroPage);

initOnlinePage("index", ListsPage);
initOnlinePage("lists", ListsPage);

initOnlinePage("settings", SettingsPage);
initOnlinePage("help", HelpPage);
initOnlinePage("about", AboutPage);
initOnlinePage("bookmarklet", BookmarkletPage, {
    url_params: ["title", "text", "url"]
});



const initDuePage = function (name) {
    const open_due_page = function () {
        const route = this.last_matched_path;
        const task_id = this.params["notes"];

        User.silent_login(function () {
            remember_last_page(route);

            import( /* webpackChunkName: "tree_pages" */ 'app/pages/due_page').then(function (pageClass) {

                const options = {type: name};
                if (task_id) {
                    options.task_id = task_id;
                    options.show_notes = true;
                }

                if (current_page instanceof pageClass.default) {
                    current_page.refresh_options(options);
                }
                else {
                    kill_current_page();
                    current_page = new pageClass.default(main(), options);
                }
            });
        }, retry_after_relogin.bind(this, route));
    };

    Path.map("/app/" + name + ".html", ["token", "notes"]).to(open_due_page);
};

initDuePage("due");
initDuePage("dueASAP");
initDuePage("dueLater");

const open_list_page = function () {
    const list_id = this.params['id'];
    const task_id = this.params['task_id'] || this.params['notes'];
    const back = this.params['back'];
    const filter = decodeURIComponent(this.params['filter'] || '');

    const route = this.last_matched_path;

    User.silent_login(function () {

        remember_last_page(route);

        if (list_id === 'new') {
            kill_current_page();
            current_page = new AddListPage(main());
        }
        else {
            import(
                /* webpackChunkName: "tree_pages" */
                'app/pages/list_page').then(function (pageClass) {

                const options = {list_id: list_id, task_id: task_id, back: back,
                    show_notes: route.indexOf('notes=') > 0,
                    filter_terms: filter.split('|').filter((t) => t.length > 0)
                };

                if (current_page instanceof pageClass.default) {
                    current_page.refresh_options(options || {});
                }
                else {
                    kill_current_page();
                    current_page = new pageClass.default(main(), options);
                }

            });
        }
    }, retry_after_relogin.bind(this, route));
};

Path.map("/app/list/:id", ["token", "notes", "filter"]).to(open_list_page);
Path.map("/app/list/:id/item/:task_id(?:back=true)").to(open_list_page);

Path.map("/cvt/:task_id").to(function () {
    const route = this.last_matched_path;
    const task_id = this.params['task_id'];
    Task.findOne({id: task_id}).then(task_data => {
        // Redirect:
        const path = "/app/list/" + task_data.checklist_id + "/item/" + task_id;
        Path.dispatch(path);
        window.history.replaceState({}, path, path);

    }, function (err) {
        app.warn("Could not find path " + route);
        cv_route.back();
    })

});

Path.map("/app/list/:id/item/:task_id/new_note.html").to(function () {
    const list_id = this.params['id'];
    const task_id = this.params['task_id'];

    const route = this.last_matched_path;

    User.silent_login(function () {
        remember_last_page(route);

        kill_current_page();
        current_page = new AddNotePage(main(), {
            list_id: list_id,
            task_id: task_id
        });
    }, retry_after_relogin.bind(this, route));
});


Path.map("/app/list/:id/new_task.html?parent_id=:parent_id&position=:position").to(function () {

    const route = this.last_matched_path;

    const that = this;
    User.silent_login(function () {
        remember_last_page(route);

        kill_current_page();
        current_page = new BookmarkletPage(main(), {
            template: "add_list_item.mustache",
            page_title: "Checkvist: " + I18N.t("add.list.item.title").toLocaleLowerCase(),

            list_id: that.params['id'],
            parent_id: that.params['parent_id'],
            position: that.params['position']
        });
    }, retry_after_relogin.bind(this, route));
});


Path.history.listen(true);

const oldPd = Path.dispatch;
Path.dispatch = function (path) {
    app.info("*******************************************************************");
    app.info(">>>>> Dispatch to  " + path);
    app.info("*******************************************************************");
    // console.trace();

    return oldPd.apply(Path, arguments);
};


window.cv_route = {
    goTo: function (path, previousPath) {

        // maxkir.warn(path, previousPath, this._from_url, this.getCurrentPath())

        // Dispatching from the path to itself
        if (path !== this.getCurrentPath()) {
            this._from_url = previousPath || this.getCurrentPath();
        }

        if (app.is_testing()) {
            Path.dispatch(path);
            return;
        }

        if (path.charAt(0) == '/') {
            Path.history.pushState({}, path, path);
        }
        else {
            document.location.href = path;
        }
    },

    from_url: function() {
        return this._from_url;
    },

    forget_from: function() {
        remember_last_page(null);
        this._from_url = null;
    },

    getCurrentPath: function() {
        if (Path.routes.current !== '/' && Path.routes.current !== '/dd') {
            return Path.routes.current;
        }
        return null;
    },

    back: function () {

        let url = this.from_url();
        this.forget_from();

        if (url) {
            if (!app.is_testing()) {
                window.history.back();
                return;
            }
            app.warn("Go back to URL " + url);
        }
        else {
            url = app.current_page().default_back_url();
            if (!url) {
                app.warn("Go back to default undefined back URL, use index instead");
                url = "/app/index.html";
            }
            app.warn("Go back to default back URL " + url);
        }

        cv_route.goTo(url);
    }
};

app.current_page = function () {
    return current_page;
};

export default {
    initial_redirect: function () {
        if (location.pathname.indexOf("/app") === 0 || location.pathname.indexOf("/cvt") === 0) {
            Path.dispatch(location.pathname + location.search);
        }
        else {
            Path.dispatch("/");
        }
    },

    /**
     * @returns {Page}
     */
    current_page: function () {
        return current_page;
    },

    kill_current_page: kill_current_page

};


